<template>
    <div class="page_container">
        <div class="page_head">
            <div class="page_head-title">MANAGE USERS</div>
          <b-form-input v-model="user_search"
                        class="search_input"
                        placeholder="Search..."
          >
          </b-form-input>

          <div class="page_head-btn_container">
            <b-button class="page_head-btn header-button" @click="synchronize('teachers')">Update teachers</b-button>
            <b-button class="page_head-btn header-button" @click="synchronize('students')">Update students</b-button>
            <b-button class="page_head-btn header-button" @click="synchronize('subjects')">Update subjects</b-button>
            <div v-if="synchronize_msg" class="synchronize_success">{{synchronize_msg}}</div>
          </div>

        </div>
        <div class="page_content">
            <div class="create_content">
                <form @submit.prevent="createUser()">

                    <div class="create_row">
                        <div class="input_item">
                            <label>Name</label>
                            <b-form-input v-model="new_user.name"
                                          class="create_input"
                                          :class="{ 'is-invalid': isSubmitted && $v.new_user.name.$error }"
                            >
                            </b-form-input>
                            <div v-if="isSubmitted && $v.new_user.name.$error" class="invalid-feedback">
                                <span v-if="!$v.new_user.name.required">Name field is required</span>
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Surname</label>
                            <b-form-input v-model="new_user.surname"
                                          class="create_input"
                                          :class="{ 'is-invalid': isSubmitted && $v.new_user.surname.$error }"
                            >
                            </b-form-input>
                            <div v-if="isSubmitted && $v.new_user.surname.$error" class="invalid-feedback">
                                <span v-if="!$v.new_user.surname.required">Surname field is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="create_row">
                        <div class="input_item">
                            <label>Email</label>
                            <b-form-input v-model="new_user.email"
                                          class="create_input"
                                          type="email"
                                          :class="{ 'is-invalid': isSubmitted && $v.new_user.email.$error }"
                                          trim
                            >
                            </b-form-input>
                            <div v-if="isSubmitted && $v.new_user.email.$error" class="invalid-feedback">
                                <span v-if="!$v.new_user.email.required">Email field is required</span>
                                <span v-if="!$v.new_user.email.email">Please provide valid email</span>
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Password</label>
                            <b-form-input v-model="new_user.password"
                                          class="create_input" :type="u_password"
                                          :class="{ 'is-invalid': isSubmitted && $v.new_user.password.$error }"
                            >
                            </b-form-input>
                            <div :class="{'eye_close': u_password == 'password', 'eye_open': u_password == 'text',}"
                                 @click="showPassword('u_password')"></div>
                            <div v-if="isSubmitted && $v.new_user.password.$error" class="invalid-feedback">
                                <span v-if="!$v.new_user.password.required">Password field is required</span>
                                <span v-if="!$v.new_user.password.minLength">Password should be at least 6 characters long</span>
                            </div>
                        </div>
                    </div>
                    <div class="create_row">
                      <div class="input_item">
                        <label>School Type</label>
                        <b-form-select
                            v-model="new_user.school_type"
                            :options="school_type"
                            value-field="value"
                            text-field="label"
                            :class="{ 'is-invalid': isSubmitted && $v.new_user.school_type.$error }"
                        >
                          <template #first>
                            <b-form-select-option value="null" disabled>Choose School</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div v-if="isSubmitted && $v.new_user.school_type.$error" class="invalid-feedback">
                          <span v-if="!$v.new_user.school_type.required">School type field is required</span>
                        </div>
                      </div>
<!--                        <div class="input_item">-->
<!--                           <label>Role</label>-->
<!--                            <b-form-select-->
<!--                                    v-model="new_user.roles"-->
<!--                                    :options="roles"-->
<!--                                    :class="{ 'is-invalid': isSubmitted && $v.new_user.roles.$error }"-->
<!--                                    value-field="id"-->
<!--                                    text-field="title"-->
<!--                            ></b-form-select>-->
<!--                            <div v-if="isSubmitted && $v.new_user.roles.$error" class="invalid-feedback">-->
<!--                                <span v-if="!$v.new_user.roles.required">Role field is required</span>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="input_item">
                            <label class="create">Create</label>
                            <b-button class="create_btn" type="submit">Create User</b-button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="users_container">
                <label>All Users</label>
                <div class="users_item" v-for="user in filteredUsers">
                    <div class="users_item-data">
                        <div class="users_item-data_date">{{getDate(user.created_at)}}</div>
                        <div class="users_item-data_name">{{user.surname}} {{user.name}} &nbsp;
                            <div :class="[user.status === 'inactive' ? 'inactive': 'active']"></div>
                        </div>
                        <div class="users_item-data_date">{{(user.roles[0] && user.roles[0].title) ? user.roles[0].title : ""}} &nbsp;|&nbsp; {{user.email}}</div>
                    </div>
                    <div class="users_right-content">
                        <b-dropdown size="lg" right  variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <div class="arrow_down"></div>
                            </template>
                            <b-dropdown-item @click="editUserModal(user)" id="edit_user-btn"><div class="action_icon edit"></div>&nbsp;Edit user information</b-dropdown-item>
                            <b-dropdown-item @click="activeDeactive(user, 'active')"><div class="action_icon active"></div>&nbsp;Active</b-dropdown-item>
                            <b-dropdown-item @click="activeDeactive(user, 'inactive')"><div class="action_icon deactive"></div>&nbsp;Deactive</b-dropdown-item>
                            <b-dropdown-item @click="deleteUserModal(user)"><div class="action_icon delete"></div>&nbsp;Delete user</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>

        <!--Edit User modal-->
        <b-modal ref="edit_user-modal" centered size="lg" hide-header hide-footer>
            <div class="modal_content">
                <div class="modal_title">
                    Edit User Information
                </div>
                <div class="modal_input">
                    <div class="input_item">
                        <label>Name</label>
                        <b-form-input v-model="edit_user.name" placeholder="User name"></b-form-input>
                    </div>
                    <div class="input_item">
                        <label>Surname</label>
                        <b-form-input v-model="edit_user.surname" placeholder="User surname"></b-form-input>
                    </div>
                </div>
                <div class="modal_input">
                    <div class="input_item">
                        <label>Status</label>
                        <b-form-select v-model="edit_user.status">
                            <b-form-select-option value="active">Active</b-form-select-option>
                            <b-form-select-option value="inactive">Deactive</b-form-select-option>
                        </b-form-select>
                    </div>
<!--                    <div class="input_item">-->
<!--                        <label>Role</label>-->
<!--                        <b-form-select-->
<!--                                v-model="edit_user.roles"-->
<!--                                :options="roles"-->
<!--                                value-field="id"-->
<!--                                text-field="title"-->
<!--                        ></b-form-select>-->
<!--                    </div>-->

                    <div v-if="edit_user.roles && edit_user.roles.title.toLowerCase() !== 'student'" class="input_item">
                      <label>School Type</label>
                      <b-form-select
                          v-model="edit_user.school_type"
                          :options="school_type"
                          value-field="value"
                          text-field="label"
                      ></b-form-select>
                    </div>

                    <div v-else class="input_item">
                      <label>Current Classroom</label>
                      <b-form-select
                          v-model="edit_user.id_class"
                          :options="classes"
                          value-field="id"
                          text-field="value"
                      ></b-form-select>
                    </div>
                </div>

              <div class="modal_input" v-if="edit_user.roles && edit_user.roles.title.toLowerCase() !== 'student'">
                <div class="input_item">
                  <label>New Password</label>
                  <b-form-input v-model="edit_user.password" :type="c_pass" placeholder="User password"></b-form-input>
                  <div :class="{'eye_close': c_pass == 'password', 'eye_open': c_pass == 'text',}"
                       @click="showPassword('c_pass')"></div>
                </div>
              </div>
                <div class="modal_btn">
                    <b-button class="cancel_btn mx-2" @click="editUserModal()">Cancel</b-button>
                    <b-button class="add_btn mx-2" @click="editUserInfo()">Edit user</b-button>
                </div>
            </div>
        </b-modal>
        <!--Edit User modal-->



        <!--Update confirmed modal-->
        <b-modal ref="edit_success-modal" centered hide-header hide-footer>
            <div class="modal_content">
                <div class="modal_title success_modal_title" v-if="user_id_todelete">
                    Delete
                </div>
                <div class="modal_title success_modal_title" v-else>
                    Success!
                </div>
                <div class="success_modal_text">{{modal_success_text}}</div>
                <div class="success_modal_buttons" v-if="user_id_todelete">
                    <b-button class="confirm_button" @click="closeSuccessModal()">Cancel</b-button>
                    <b-button class="confirm_button" @click="deleteUser()">Confirm</b-button>
                </div>
                <div class="success_modal_buttons" v-else>
                    <b-button class="confirm_button" @click="closeSuccessModal()">Continue</b-button>
                </div>
            </div>
        </b-modal>
        <!--Edit User modal-->

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import {email, minLength, required} from "vuelidate/lib/validators";

    export default {
        name: "ManageUsers",
        data() {
            return {
              user_id_todelete: null,
              modal_success_text: "User updated succesfully.",
              synchronize_msg: null,
              user_search: '',
                new_user: {
                  name: '',
                  surname: '',
                  school_type: null,
                  email: '',
                  password: '',
                  roles: ''
                },
                isSubmitted: false,
                userError: null,
                u_password: 'password',
                c_pass: 'password',
                selected_user: '',
                selected_role: '',
                users: [],
                roles: [],
                classes: [],
                school_type: [
                  {
                    label: 'Primary School',
                    value: 'primary'
                  },
                  {
                    label: 'Middle School',
                    value: 'middle'
                  },
                  {
                    label: 'High School',
                    value: 'high'
                  },
                  {
                    label: 'Middle and High School',
                    value: 'middle_high'
                  }
                ],
                edit_user: {
                    id: null,
                    name: null,
                    surname: null,
                    status: null,
                    roles: null,
                    school_type: null,
                    password: null
                }
            }
        },
        validations: {
            new_user: {
                name: {
                    required
                },
                surname: {
                    required
                },
                school_type: {
                  required
                },
                // roles: {
                //     required
                // },
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
            }
        },
        computed: {
          filteredUsers() {
            let filteredName = this.users.filter((user) => {
              return (user.surname && user.surname.toLowerCase().includes(this.user_search.toLowerCase())) ||
              (user.name != null && user.name.toLowerCase().includes(this.user_search.toLowerCase()));
            })
            return filteredName
          },

        },
        created() {
            this.getUsers();
            this.getClassesData();
        },
        methods: {
            ...mapActions([
                'getUserRoles',
                'roleChange',
                'getAllUsers',
                'createNewUser',
                'removeUser',
                'editUserData',
                'synchronizeData',
                'getSubjectClassTopic'
            ]),
            getClassesData() {
            this.getSubjectClassTopic()
                .then((res) => this.classes = res.data.classes )
            },
            getDate(item) {
                let d = new Date(item).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
                return d
            },
            getUsers() {
                this.getAllUsers()
                .then(res => {
                    const students = res.data.students.map(s => { 
                        let roles = [{title:"Student"}];
                        return { 
                            email:s.emailStudente, 
                            id:s.id, 
                            name:s.nome, 
                            id_class:s.idClasse, 
                            surname: 
                            s.cognome, 
                            status:s.flagAttivo == 1 ? "active" : "inactive", 
                            roles:roles, 
                            created_at: s.created_at
                        } 
                    })
                    this.users = res.data.teachers.concat(students);
                })
                this.getUserRoles()
                    .then(res => {
                        this.roles = res.data[1]
                    })
            },
          async synchronize(data) {
            await this.synchronizeData(data)
                .then(res => {
                  this.synchronize_msg = res.data.message
                  setTimeout(() => {
                    this.synchronize_msg = null
                  }, 5000)
                  this.getUsers()
                })
            },
            createUser() {
                this.isSubmitted = true;
                this.userError = null
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                } else {
                    this.createNewUser(this.new_user)
                        .then((res) => {
                            if (res) {
                                this.modal_success_text = "User created succesfully.";
                                this.$refs['edit_success-modal'].toggle('#edit_user-btn');

                                this.getUsers()
                                this.isSubmitted = false;
                                this.new_user.name = ''
                                this.new_user.surname = ''
                                this.new_user.school_type = null
                                this.new_user.email = ''
                                this.new_user.password = ''
                                this.new_user.roles = ''
 
                            } else {
                                this.userError = res.response.data.error
                            }
                        })
                }
            },
            showPassword(item) {
              if(item == 'c_pass') {
                if (this.c_pass === 'password') {
                  this.c_pass = 'text'
                } else {
                  this.c_pass = 'password'
                }
              } else {
                if (this.u_password === 'password') {
                  this.u_password = 'text'
                } else {
                  this.u_password = 'password'
                }
              }

            },
            deleteUserModal(user) {
                this.user_id_todelete = user.id;
                this.modal_success_text = "Are you sure you want to delete the user " + user.name + " " + user.surname + "?";
                this.$refs['edit_success-modal'].toggle('#edit_user-btn');
            },
            deleteUser() {
                this.removeUser(this.user_id_todelete)
                .then(res => {
                    this.closeSuccessModal();
                    if(res.status == 200) {
                        setTimeout(() => {
                            this.modal_success_text = "User deleted succesfully.";
                            this.$refs['edit_success-modal'].toggle('#edit_user-btn');
                        }, 500)
                        
                        this.getUsers()
                    }
                })
            },
            activeDeactive(user, status) {
                let sendData = {
                    id: user.id,
                    name: user.name,
                    surname: user.surname,
                    roles: user.roles[0],
                    id_class: user.id_class,
                    status: status
                }
                this.editUserData(sendData)
                    .then(res => {
                        if(res) {
                            this.getUsers()
                        }
                    })
            },
            closeSuccessModal(){
                setTimeout(() => this.user_id_todelete = null, 500)
                this.$refs['edit_success-modal'].hide();
            },
            editUserModal(user) {
                if(user) {
                    this.edit_user.id = user.id
                    this.edit_user.name = user.name
                    this.edit_user.surname = user.surname
                    this.edit_user.roles = user.roles[0]
                    this.edit_user.status = user.status
                    this.edit_user.school_type = user.school_type
                    this.edit_user.id_class = user.id_class
                    this.edit_user.password = null
                }
                this.$refs['edit_user-modal'].toggle('#edit_user-btn')
            },
            editUserInfo() {
                this.editUserData(this.edit_user)
                    .then(res => {
                        if (res && res.status == 201) {
                            console.log(res)
                            this.modal_success_text = "User updated succesfully.";
                            this.$refs['edit_success-modal'].toggle('#edit_user-btn');
                            this.getUsers()
                        }
                        this.$refs['edit_user-modal'].toggle('#edit_user-btn')
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
.header-button{
    height: 50px!important;
}

.confirm_button{
    height: 40px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    background: #00A99D;
    color: #FFFFFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px 20px;
    cursor: pointer;
}

.success_modal_text, .success_modal_buttons, .success_modal_title{
    display: flex;
    width: 100%;
    justify-content: center;
} 

.success_modal_text{
    padding-top: 20px;
    padding-bottom: 40px;
}

.page {
    &_container {
        width: 100%;
    }
    &_head {
        width: 100%;
        height: 73px;
        background: linear-gradient(180deg, #003761 0%, #00528C 79.64%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        position: sticky;
        top: 0;
        z-index: 3;
        & .search_input {
          max-width: 40%;
          &::placeholder {
            color: #00A99D;
            font-size: 14.173px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        &-title {
            color: #FFFFFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        &-btn {
          height: 44px;
          border-radius: 12px;
          border: 1px solid #00A99D;
          background: #00A99D;
          color: #FFFFFF;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 25px;
          margin: 0 10px;
          cursor: pointer;
          &_container {
            display: flex;
            position: relative;
            & .synchronize_success {
              position: absolute;
              bottom: -45px;
              right: 10px;
              padding: 0 15px;
              color: #00A99D;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }
          &:active,
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
    }
    &_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 40px 60px;
        & .users {
            &_container {
                max-width: 1100px;
                width: 100%;
                padding-top: 20px;
                border-top: 1px dashed #11334D;
                & label {
                    color: #11334D;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
            &_item {
                margin: 8px 0;
                padding: 15px;
                width: 100%;
                height: 100px;
                border-radius: 12px;
                border: 1px solid #11334D;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &-data {
                    &_date {
                        color: rgba(17, 51, 77, 0.60);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        padding: 3px 0;
                    }
                    &_name {
                        display: flex;
                        align-items: center;
                        color: #11334D;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        & .inactive {
                            width: 20px;
                            height: 20px;
                            background: url("~@/assets/icons/deactive.svg") no-repeat center;

                        }
                        & .active {
                            width: 20px;
                            height: 20px;
                            background: url("~@/assets/icons/active.svg") no-repeat center;

                        }
                    }
                }
            }
            &_right-content {
                ::v-deep .dropdown-toggle {
                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
                & .arrow_down {
                    width: 40px;
                    height: 40px;
                    background: url("~@/assets/icons/arrow_down.svg")  no-repeat center;
                }
                & .action_icon {
                    width: 15px;
                    height: 15px;
                    margin: 0 5px;
                    &.edit {
                        background: url("~@/assets/icons/edit_pen.svg")  no-repeat center;
                    }
                    &.active {
                        background: url("~@/assets/icons/active.svg")  no-repeat center;
                    }
                    &.deactive {
                        background: url("~@/assets/icons/deactive.svg")  no-repeat center;
                    }
                    &.delete {
                        background: url("~@/assets/icons/delete.svg")  no-repeat center;
                    }
                }
                ::v-deep ul li a {
                    display: flex !important;
                    align-items: center;
                    color: #11334D;
                    font-size: 15px;
                    font-weight: 500;
                    &:active {
                        background: #00A99D;
                        color: #ffffff;
                    }
                }
            }
        }
        & .create {
            &_content {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-bottom: 15px;
            }
            &_row {
                max-width: 1100px;
                width: 100%;
                display: flex;
                align-items: flex-end;
                & .input_item {
                    padding: 5px 20px 5px 0;
                    height: 115px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    & label {
                        color: #11334D;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        &.create {
                            color: transparent;
                            visibility: hidden;
                        }
                    }
                    & input {
                        border-radius: 8px;
                        border: 1px solid #11334D;
                        width: 470px;
                        height: 44px !important;
                        /*background: url("~@/assets/icons/arrow_down.svg")  no-repeat;*/
                        /*background-position: calc(100% - 0.7rem) center !important;*/
                        /*-moz-appearance:none !important;*/
                        /*-webkit-appearance: none !important;*/
                        /*appearance: none !important;*/
                        /*padding-right: 2rem !important;*/
                        color: #11334D;
                        font-size: 18px;
                        font-style: italic;
                        font-weight: 500;
                    }
                    & select {
                        border-radius: 8px;
                        border: 1px solid #11334D;
                        width: 470px;
                        height: 44px !important;
                        background: url("~@/assets/icons/arrow_down.svg")  no-repeat;
                        background-position: calc(100% - 0.7rem) center !important;
                        -moz-appearance:none !important;
                        -webkit-appearance: none !important;
                        appearance: none !important;
                        padding-right: 2rem !important;
                        color: #11334D;
                        font-size: 18px;
                        font-style: italic;
                        font-weight: 500;
                    }
                    & .eye_open {
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        right: 35px;
                        bottom: 38px;
                        background: url("~@/assets/icons/eye_open.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        cursor: pointer;
                    }
                    & .eye_close {
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        right: 35px;
                        bottom: 38px;
                        background: url("~@/assets/icons/eye_close.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        cursor: pointer;
                    }
                    & .create_btn {
                        height: 44px;
                        border-radius: 12px;
                        border: 1px solid #FFFFFF;
                        background: #00A99D;
                        color: #FFFFFF;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 5px 25px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.modal {
    &_content {
        padding: 10px 20px;
    }

    &_title {
        color: #11334D;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 10px 0;
    }

    &_input {
        margin: 10px 0;
        width: 100%;
        display: flex;
        align-items: center;
        & .input_item {
            max-width: 330px;
            width: 100%;
            margin: 5px 30px 5px 0;
            position: relative;
            & input {
                border: 1px solid #11334D;

                &::placeholder {
                    color: #11334D;
                    font-size: 18px;
                    font-style: italic;
                    font-weight: 500;
                    line-height: normal;
                }
            }
            & label {
                color: #11334D;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            & select {
                border-radius: 8px;
                border: 1px solid #11334D;
                background: url("~@/assets/icons/arrow_down.svg")  no-repeat;
                background-position: calc(100% - 0.7rem) center !important;
                -moz-appearance:none !important;
                -webkit-appearance: none !important;
                appearance: none !important;
                padding-right: 2rem !important;
                color: #11334D;
                font-size: 18px;
                font-style: italic;
                font-weight: 500;
            }
            & .eye_open {
              position: absolute;
              width: 24px;
              height: 24px;
              right: 15px;
              bottom: 5px;
              background: url("~@/assets/icons/eye_open.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              cursor: pointer;
            }
            & .eye_close {
              position: absolute;
              width: 24px;
              height: 24px;
              right: 15px;
              bottom: 5px;
              background: url("~@/assets/icons/eye_close.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              cursor: pointer;
            }
        }


    }

    &_btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 0 10px;

        & .cancel_btn,
        & .add_btn {
            height: 40px;
            border-radius: 12px;
            border: 1px solid #FFFFFF;
            background: #00A99D;
            color: #FFFFFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 20px;
            cursor: pointer;
        }
    }
}
::v-deep .form-control.is-invalid {
    background-image: none !important;
}
</style>
